import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";
import Globais from "../../services/globais.js";
import { useHistory } from "react-router-dom";
import TicketUserModal from "../TicketUserModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const ChangeQueueModal = ({ open, onClose, userId, contactId }) => {
  const classes = useStyles();
  const history = useHistory();

  const initialState = {
    name: "",
    email: "",
    password: "",
    profile: "user",
  };
  const setContactId = Globais.contactId;
  const { user: loggedInUser } = useContext(AuthContext);

  const [user, setUser] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [whatsappId, setWhatsappId] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [showTicketUser, setShowTicketUser] = useState(false);
  const [userName, setUserName] = useState("");
  const [loginUser, setLoginUser] = useState("");
  const [whatsappOptions, setWhatsappOptions] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      try {
        const { data } = await api.get(`/users/${userId}`);
        setUser((prevState) => ({ ...prevState, ...data }));
        const userQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(userQueueIds);
        setWhatsappId(data.whatsappId ? data.whatsappId : "");

        if (data.whatsapp && Array.isArray(data.whatsapp.whatsapp)) {
          setWhatsappOptions(data.whatsapp.whatsapp);
        } else {
          
          setWhatsappOptions([]);
        }
      } catch (err) {
        toastError(err);
      }
    };

    const fetchTemplates = async () => {
      try {
        const { data } = await api.get("/templates"); 
        setTemplates(data);
      } catch (err) {
        toastError(err);
      }
    };

    fetchUser();
    fetchTemplates();
  }, [userId, open]);

  const handleClose = () => {
    onClose();
    setUser(initialState);
    setWhatsappOptions([]);
  };

  const handleSaveTicket = async () => {
    setLoading(true);

    if (!contactId) {
      toastError("Nenhum contato foi selecionado!");
      return;
    }
    if (!selectedQueueIds || selectedQueueIds.length <= 0) {
      toastError("Nenhum setor foi selecionado!");
      return;
    }
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        userId: user?.id,
        queueId: selectedQueueIds[0],
        status: "open",
        whatsappId: whatsappId || undefined, 
        templateId: Number(selectedTemplate) || undefined
      });

      history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      const errorMsg = err.response?.data?.message || err.response.data.error;
      if (errorMsg) {
        const ticketError = errorMsg.split("|");
        setTicketId(ticketError[1]);
        setUserName(ticketError[2]);
        setLoginUser(ticketError[3]);
        setShowTicketUser(true);
      }
    }
    setLoading(false);
    handleClose();
  };

  const handleCloseQueueModal = async () => {
    setShowTicketUser(false);
  };

  return (
    <div className={classes.root}>
      <TicketUserModal
        open={showTicketUser}
        onClose={handleCloseQueueModal}
        aria-labelledby="form-dialog-title"
        userName={userName}
        login={loginUser}
        userId={user?.id}
        ticketId={ticketId}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          Atribua um setor para o Chat
        </DialogTitle>
        <DialogContent dividers>
          <QueueSelect
            selectedQueueIds={selectedQueueIds}
            onChange={(values) => setSelectedQueueIds(values)}
            title={"Filas"}
          />
          {whatsappOptions.length > 0 && (
            <FormControl
			variant="outlined"
			margin="dense"
			className={classes.maxWidth}
			fullWidth
		  >
			<InputLabel id="whatsapp-select-label">
			  {"Conexão"}
			</InputLabel>
			<Select
			  labelId="whatsapp-select-label"
			  value={whatsappId}
			  onChange={(e) => setWhatsappId(e.target.value)}
			>
			  <MenuItem value="">
				&nbsp;
			  </MenuItem>
			  {whatsappOptions.map((whatsapp) => (
				<MenuItem key={whatsapp.id} value={whatsapp.id}>
				  {whatsapp.name}
				</MenuItem>
			  ))}
			</Select>
		  </FormControl>)}
      {templates.length > 0 && (
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth
            >
              <InputLabel id="template-select-label">Template</InputLabel>
              <Select
                labelId="template-select-label"
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
              >
                <MenuItem value="">
                  &nbsp;
                </MenuItem>
                {templates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            {i18n.t("userModal.buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.btnWrapper}
            onClick={handleSaveTicket}
          >
            {userId ? `Iniciar Chat` : `Iniciar Chat`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangeQueueModal;
