import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { DeleteOutline, Edit } from "@material-ui/icons";
import TemplateModal from "../../components/TemplateModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import openSocket from "../../services/socket-io";
import api from "../../services/api";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
  },
}));

const initialTemplates = [];

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_TEMPLATES":
      return action.payload;
    case "UPDATE_TEMPLATE":
      return state.map((template) =>
        template.id === action.payload.id ? action.payload : template
      );
    case "DELETE_TEMPLATE":
      return state.filter((template) => template.id !== action.payload);
    case "ADD_TEMPLATE":
      return [...state, action.payload];
    default:
      return state;
  }
};

const Templates = () => {
  const classes = useStyles();
  const [templates, dispatch] = useReducer(reducer, initialTemplates);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const fetchTemplates = async () => {
    try {
      const { data } = await api.get("/templates");
      dispatch({ type: "LOAD_TEMPLATES", payload: data });
    } catch (error) {
      console.error("Erro ao carregar templates:", error);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("template", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_TEMPLATE", payload: data.template });
      } else if (data.action === "delete") {
        dispatch({ type: "DELETE_TEMPLATES", payload: data.templateId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenTemplateModal = () => {
    setTemplateModalOpen(true);
    setSelectedTemplate(null);
  };

  const handleCloseTemplateModal = () => {
    setTemplateModalOpen(false);
    setSelectedTemplate(null);
  };

  const handleEditTemplate = (template) => {
    setSelectedTemplate(template);
    setTemplateModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedTemplate(null);
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      await api.delete(`/templates/${templateId}`);
      dispatch({ type: "DELETE_TEMPLATE", payload: templateId });
      toast.success("Template deletado com sucesso!");
    } catch (error) {
      toast.error("Erro ao deletar o template.");
    } finally {
      setConfirmModalOpen(false);
    }
  };

  // Para o user local ver as mudancas feitas
  const handleSaveTemplate = (template) => {
    if (selectedTemplate) {
      dispatch({ type: "UPDATE_TEMPALTE", payload: template });
    } else {
      dispatch({ type: "ADD_TEMPLATE", payload: template });
    }
    handleCloseTemplateModal();
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={selectedTemplate && `Deseja deletar ${selectedTemplate.name}?`}
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteTemplate(selectedTemplate.id)}
      >
        Tem certeza que deseja deletar esse template?
      </ConfirmationModal>
      <TemplateModal
        open={templateModalOpen}
        onClose={handleCloseTemplateModal}
        templateId={selectedTemplate?.id}
        onSave={handleSaveTemplate}
      />

      <MainHeader>
        <Title>Templates</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenTemplateModal}
          >
            Adicionar Template
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Descrição</TableCell>
              <TableCell align="center">Tipo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map((template) => (
              <TableRow key={template.id}>
                <TableCell align="center">{template.name}</TableCell>
                <TableCell
                  align="center"
                  style={{
                    maxWidth: 100,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {template.description}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditTemplate(template);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedTemplate(template);
                      setConfirmModalOpen(true);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Templates;
