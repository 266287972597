import React, { useState, useEffect, useReducer, useRef } from "react";

import { isSameDay, parseISO, format } from "date-fns";
import openSocket from "socket.io-client";
import clsx from "clsx";

import { green } from "@material-ui/core/colors";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
  Typography,
  Grid,
  Modal,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
  Close,
  Reply,
} from "@material-ui/icons";

import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";
import ArrowCircleUp from "@material-ui/icons/ArrowUpward";
import ArrowCircleDown from "@material-ui/icons/ArrowDownward";
import { Marker } from "react-mark.js";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import Globais from "../../services/globais";

import ContactListShareModal from "../ContactListShareModal";

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  ticketNunber: {
    color: "#808888",
    padding: 8,
  },

  messageCenter: {
    marginTop: 5,
    alignItems: "center",
    verticalAlign: "center",
    alignContent: "center",
    backgroundColor: "#E1F5FEEB",
    fontSize: "12px",
    minWidth: 100,
    maxWidth: 270,
    color: "#272727",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messagesList: {
    backgroundImage: `url(${whatsBackground})`,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffffff",
    color: "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",

    "&.ptvMessage": {
      width: "fit-content",
      height: "fit-content",
      borderRadius: "202px",
    },
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 20,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: green[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },
  search: {
    width: 100,
    backgroundColor: "#ffffff",
    //padding : 10,
    //alignSelf: "self-end",
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,
    right: "3%",
    position: "absolute",
    borderEndEndRadius: "13px",
    borderEndStartRadius: "13px",
  },
  highlighter: {
    highlighted: {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      //boxShadow: -3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7; // Creates an illusion of a wider span, while not affecting the actual width.
    },
  },
  videoContainer: {
    overflow: "hidden",
    width: "200px",
    height: "200px",
    borderRadius: "200px",
  },
  reactions: {
    marginTtop: "2px",
    display: "flex",
    gap: "6px",
    alignItems: "center",
    position: "absolute",
  },
  reactionEmoji: {
    fontSize: "12px",
    padding: "4px 8px",
    background: "#f1f1f1",
    borderRadius: "16px",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    transition: "transform 0.2s ease",
  },

  container: {
    position: "absolute",
    left: "55vw",
    bottom: "-20px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },

  forwardContainer: {
    backgroundColor: "#EEEEEE",
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 1rem",
    position: "absolute",
    zIndex: "10",
    bottom: 0,
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MessagesList = ({ ticketId, isGroup }) => {
  const classes = useStyles();

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();
  const [searchParam, setSearchParam] = useState(1);

  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);

  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isForwarding, setIsForwarding] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const [isShareContactModalOpen, setIsShareContactModalOpen] = useState(false);
  const [sharedContacts, setSharedContacts] = useState([]);

  var numlist = -1;
  let array_list;
  let return_div = true;

  useEffect(() => {
    dispatch({ type: "RESET" });
    if (Globais.searchParam != null) {
      setPageNumber(400);
      setSearchParam(Globais.searchParam);
    } else {
      setPageNumber(1);
    }

    currentTicketId.current = ticketId;
  }, [ticketId]);

  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        const viewPage = Globais.viewPage;
        try {
          if (viewPage === "true") {
            const { data } = await api.get("/messages/" + ticketId, {
              params: { pageNumber, viewPage, searchParam },
            });

            if (currentTicketId.current === ticketId) {
              dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
              setHasMore(data.hasMore);
              setLoading(false);
            }

            if (data.array_ids.length > 0) {
              Globais.array_ids = data.array_ids;
              array_list = data.array_ids;
            }

            if (pageNumber === 1 && data.messages.length > 1) {
              scrollToBottom();
            }
            if (pageNumber === 400) {
              scrollToBottom();
            }
            //Resetando o valor da variavel global viewPage.
            Globais.viewPage = "false";
          } else {
            const { data } = await api.get("/messages/" + ticketId, {
              params: { pageNumber, searchParam },
            });

            if (currentTicketId.current === ticketId) {
              const processedMessages = await Promise.all(
                data.messages.map(
                  async (message) => await processMessage(message)
                )
              );
              dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
              setHasMore(data.hasMore);
              setLoading(false);
            }

            if (data.array_ids.length > 0) {
              Globais.array_ids = data.array_ids;
              array_list = data.array_ids;
            }

            if (pageNumber === 1 && data.messages.length > 1) {
              scrollToBottom();
            }
            if (pageNumber === 400) {
              scrollToBottom();
            }
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("appMessage", (data) => {
      if (data.action === "create") {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom();
      }

      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  //implementado para poder localizar uma palavra
  const handleClickScroll = (e) => {
    const element = document.getElementById(e);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ block: "center", behavior: "smooth" });

      //document.getElementById(e).scrollTop -= 10;

      /*element.scrollIntoView(true);
      window.scrollBy(0, -100);*/
    }
  };

  const handleScroll = (e) => {
    if (!hasMore) return;
    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const handleButtonClick = (button) => {
    console.log("Botão clicado:", button);
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    handleClose();
    console.log("Opção selecionada:", option);
  };

  const checkMessageMedia = (message) => {
    if (message.mediaType === "interactiveListMessage") {
      const sections = message.dataJson?.inputs?.sections || [];

      const buttonText = message.dataJson?.inputs?.buttonText || "Lista";

      return (
        <div style={{ padding: 8 }}>
          <MarkdownWrapper>{message.body}</MarkdownWrapper>

          <Button
            style={{ marginTop: 10 }}
            variant="contained"
            color="primary"
            onClick={handleOpen}
          >
            {buttonText}
          </Button>

          <Modal
            open={open}
            onClose={handleClose}
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
          >
            <div className={classes.container}>
              <Typography variant="h6" gutterBottom>
                {buttonText}
              </Typography>
              <List>
                {sections.map((section, index) =>
                  section.rows.map((row) => (
                    <ListItem
                      button
                      key={row.id}
                      onClick={() => handleSelectOption(row.title)}
                      disabled={message.fromMe}
                    >
                      <ListItemText primary={row.title} />
                    </ListItem>
                  ))
                )}
              </List>
            </div>
          </Modal>
        </div>
      );
    } else if (message.mediaType === "interactiveMessage") {
      const buttons = message.dataJson?.inputs?.buttons || [];

      return (
        <div style={{ padding: 8 }}>
          <MarkdownWrapper>{message.body}</MarkdownWrapper>
          {buttons.length > 0 && (
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              {buttons.map((button, index) => (
                <Grid item key={button.id}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleButtonClick(button)}
                    disabled={message.fromMe}
                  >
                    {button.title}
                  </Button>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      );
    } else if (message.mediaType === "locationMessage") {
      const body = JSON.parse(message.body);
      const description = null;
      const messageId = message.id;
      const latitude = body.degreesLatitude;
      const longitude = body.degreesLongitude;

      if (latitude && longitude) {
        return (
          <LocationPreview
            latitude={latitude}
            longitude={longitude}
            description={description}
            messageId={messageId}
          />
        );
      }
    } else if (message.mediaType === "contactMessage") {
      //console.log("vcard")
      //console.log(message)
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      return <VcardPreview contact={contact} numbers={obj[0].number} />;
    } else if (message.mediaType === "image") {
      /*else if (message.mediaType === "multi_vcard") {
		console.log("multi_vcard")
		console.log(message)
		
		if(message.body !== null && message.body !== "") {
			let newBody = JSON.parse(message.body)
			return (
				<>
				  {
					newBody.map(v => (
					  <VcardPreview contact={v.name} numbers={v.number} />
					))
				  }
				</>
			)
		} else return (<></>)
	}*/
      return <ModalImageCors imageUrl={message.mediaUrl} />;
    } else if (message.mediaType === "audio") {
      return (
        <audio controls>
          <source src={message.mediaUrl} type="audio/ogg"></source>
        </audio>
      );
    } else if (message.mediaType === "video") {
      return (
        <video
          className={classes.messageMedia}
          src={message.mediaUrl}
          controls
        />
      );
    } else if (message.mediaType === "ptvMessage") {
      return (
        <div className={classes.videoContainer}>
          <video
            className={classes.messageMedia}
            src={message.mediaUrl}
            controls
          />
        </div>
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={message.mediaUrl}
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 1) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 4 || message.ack === 5) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderNumberTicket = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageTicket = message.ticketId;
      let previousMessageTicket = messagesList[index - 1].ticketId;

      if (messageTicket !== previousMessageTicket) {
        return (
          <div key={`ticket-${message.id}`} className={classes.ticketNunber}>
            #ticket: {messageTicket}
            <hr />
          </div>
        );
      }
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}
          {message.quotedMsg?.body}
        </div>
      </div>
    );
  };

  const mentionRegex = /@(\d{10,15})/;

  const getContactName = async (phoneNumber) => {
    try {
      const response = await api.post("/contact", {
        number: phoneNumber,
        name: phoneNumber,
      });
      const data = await response.data;

      // Se o nome retornado for o próprio número, mantemos o  número
      return data.name !== phoneNumber ? data.name : phoneNumber; // Retorna o nome se não for igual ao número
    } catch (error) {
      console.error("Error fetching contact name:", error);
      return phoneNumber; // retorna o número
    }
  };

  const processMessage = async (message) => {
    const matches = message.body.match(mentionRegex); // Encontra todas as menções de números

    if (matches) {
      // Para cada número encontrado, enviar ao backend

      for (let match of matches) {
        const phoneNumber = match.slice(1); // Remove o "@" para obter o número
        const contactName = await getContactName(phoneNumber);

        const formatterMention = "*@" + contactName + "*";
        // Substitui o número pelo nome na mensagem
        message.body = message.body.replace(match, formatterMention);
      }
    }
    return message;
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const messageMap = {};
      const reactionsMap = {};

      messagesList.forEach((msg) => {
        if (msg.mediaType === "reactionMessage" && msg.reactedMsgId) {
          // Adicionar reação à mensagem original
          if (!reactionsMap[msg.reactedMsgId]) {
            reactionsMap[msg.reactedMsgId] = [];
          }
          reactionsMap[msg.reactedMsgId].push(msg.body);
        } else {
          messageMap[msg.id] = msg;
        }
      });
      const viewMessagesList = Object.values(messageMap).map(
        (message, index) => {
          const reactions = reactionsMap[message.id] || [];

          if (message.mediaType === "call_log") {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderNumberTicket(message, index)}
                {renderMessageDivider(message, index)}
                <div className={classes.messageCenter}>
                  <IconButton
                    variant="contained"
                    size="small"
                    id="messageActionsButton"
                    disabled={message.isDeleted}
                    className={classes.messageActionsButton}
                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>
                  {isGroup && (
                    <span className={classes.messageContactName}>
                      {message.contact?.name}
                    </span>
                  )}
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                      width="20"
                      height="17"
                    >
                      <path
                        fill="#df3333"
                        d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"
                      ></path>
                    </svg>{" "}
                    <span>
                      Chamada de voz/vídeo perdida às{" "}
                      {format(parseISO(message.createdAt), "HH:mm")}
                    </span>
                  </div>
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderNumberTicket(message, index)}
                {renderMessageDivider(message, index)}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: message.fromMe ? "flex-end" : "flex-start",
                  }}
                >
                  {isForwarding && (
                    <input
                      type="checkbox"
                      id={`forwardCheckbox-${message.id}`}
                      checked={selectedMessages.some(
                        (msg) => msg.id === message.id
                      )}
                      onChange={(e) => handleCheckboxChange(e, message)}
                    />
                  )}
                  <div
                    style={{ justifySelf: "end" }}
                    className={
                      message.fromMe
                        ? classes.messageRight
                        : `${classes.messageLeft} ${
                            message.mediaType === "ptvMessage"
                              ? "ptvMessage"
                              : ""
                          }`
                    }
                  >
                    <Marker mark={Globais.searchParam}>
                      <IconButton
                        variant="contained"
                        size="small"
                        id="messageActionsButton"
                        disabled={message.isDeleted}
                        className={classes.messageActionsButton}
                        onClick={(e) =>
                          handleOpenMessageOptionsMenu(e, message)
                        }
                      >
                        <ExpandMore />
                      </IconButton>
                      {isGroup && !message.fromMe && (
                        <span className={classes.messageContactName}>
                          {message.contact?.name}
                        </span>
                      )}
                      {(message.mediaUrl ||
                        message.mediaType === "contactMessage" ||
                        message.mediaType === "locationMessage" ||
                        message.mediaType === "interactiveListMessage" ||
                        message.mediaType === "interactiveMessage") &&
                        checkMessageMedia(message)}
                      <div
                        className={clsx(classes.textContentItem, {
                          [classes.textContentItemDeleted]: message.isDeleted,
                        })}
                        id={message.id}
                      >
                        {message.quotedMsg && renderQuotedMessage(message)}
                        {message.mediaType !== "locationMessage" &&
                          message.mediaType !== "interactiveListMessage" &&
                          message.mediaType !== "interactiveMessage" && (
                            <MarkdownWrapper>{message.body}</MarkdownWrapper>
                          )}
                        <div
                          style={{
                            paddingTop: "10px",
                            display: "flex",
                            columnGap: "15px",
                          }}
                        >
                          {message.mediaType === "editedMessage" && (
                            <small
                              style={{
                                position: "absolute",
                                bottom: "1px",
                                right: "60px",
                                color: "#999",
                              }}
                            >
                              Editada
                            </small>
                          )}
                          <span className={classes.timestamp}>
                            {format(parseISO(message.createdAt), "HH:mm")}
                            {renderMessageAck(message)}
                          </span>
                        </div>
                        {/* reações */}
                        {reactions.length > 0 && (
                          <div className={classes.reactions}>
                            {reactions.map((reaction, i) => (
                              <span key={i} className={classes.reactionEmoji}>
                                {reaction}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    </Marker>
                  </div>
                </div>
              </React.Fragment>
            );
          }
        }
      );

      return viewMessagesList;
    } else {
      return <div>Say hello to your new contact!</div>;
    }
  };

  const handleForwardActivated = (isActivated) => {
    setIsForwarding(isActivated);
  };

  const handleCheckboxChange = (e, message) => {
    const isChecked = e.target.checked;

    console.log("Checkbox changed:", {
      isChecked,
      message,
      selectedMessages: [...selectedMessages],
    });

    setSelectedMessages((prevSelectedMessages) => {
      if (isChecked) {
        return [...prevSelectedMessages, message];
      } else {
        return prevSelectedMessages.filter((msg) => msg.id !== message.id);
      }
    });
  };

  const handleCloseForward = () => {
    setIsForwarding(false);
    setSelectedMessages([]);
  };

  const handleSendForward = () => {
    setIsShareContactModalOpen(true);
    console.log("Reply clicked", selectedMessages);
  };

  const handleForwardMessage = async (contacts) => {
    try {
      await Promise.all(
        contacts.map(async (contact) => {
          await Promise.all(
            selectedMessages.map(async (message) => {
              const forwardData = {
                contactId: contact.id,
                messageId: message.id,
                number: contact.number,
                isGroup: contact.isGroup,
              };

              await api.post(
                `/messages/forward/${message.ticketId}`,
                forwardData
              );
            })
          );
        })
      );

      // Fechar o modal e limpar os dados após o envio
      setIsShareContactModalOpen(false);
      setSharedContacts([]);
      handleCloseForward();
    } catch (err) {
     // toastError(err);
    }
  };

  return (
    <div className={classes.messagesListWrapper}>
      {Globais.searchParam !== null && (
        <div className={classes.search}>
          <IconButton
            onClick={() => {
              if (Globais.array_ids.length <= numlist + 1) {
                //Não faz nada pois chegou no ultimo item do array
              } else {
                numlist = numlist + 1;
              }
              //Verifica se acabou os itens do array e não permite subir mais na procura
              const scroll_item = Globais.array_ids[numlist];
              if (Globais.array_ids.length == 1) {
                console.log(
                  "Quantidade de palavras: ",
                  Globais.array_ids.length
                );
                console.log("Buscando palavra: ", Globais.searchParam);
                handleClickScroll(Globais.array_ids.length);
              } else {
                console.log("Quantidade de palavras: ", scroll_item);
                console.log("Buscando palavra: ", Globais.searchParam);
                handleClickScroll(scroll_item);
              }
            }}
          >
            <ArrowCircleUp />
          </IconButton>
          <IconButton
            onClick={() => {
              if (numlist == 0) {
                //não subtrai pois já chegou no primeiro item do array
              } else {
                numlist = numlist - 1;
              }
              //Verifica se acabou os itens do array e não permite subir mais na procura
              const scroll_item = Globais.array_ids[numlist];

              if (Globais.array_ids.length == 1) {
                handleClickScroll(Globais.array_ids.length);
                console.log(
                  "Quantidade de palavras: ",
                  Globais.array_ids.length
                );
                console.log("Buscando palavra: ", Globais.searchParam);
              } else {
                console.log("Quantidade de palavras: ", scroll_item);
                console.log("Buscando palavra: ", Globais.searchParam);
                handleClickScroll(scroll_item);
              }
            }}
          >
            <ArrowCircleDown />
          </IconButton>
        </div>
      )}
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
        onForwardActivated={handleForwardActivated}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {messagesList.length > 0 ? renderMessages() : []}
      </div>
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}

      {isShareContactModalOpen && (
        <ContactListShareModal
          onSelectContacts={handleForwardMessage}
          onClose={() => setIsShareContactModalOpen(false)}
        />
      )}

      {isForwarding && (
        <div className={classes.forwardContainer}>
          <Button onClick={handleCloseForward}>
            <Close />
          </Button>
          <Button onClick={handleSendForward}>
            <Reply style={{ transform: "scaleX(-1)" }} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default MessagesList;
