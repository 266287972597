import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  buttonProgress: {
    position: "absolute",
    marginLeft: theme.spacing(1),
  },
}));

const TemplateSchema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  description: Yup.string(),
  type: Yup.string().required("Campo obrigatório"),
  lang: Yup.string().required("Campo obrigatório"),
});

const TemplateModal = ({ open, onClose, templateId, onSave }) => {
  const classes = useStyles();
  const initialState = { name: "", description: "", type: "", lang: "" };
  const [template, setTemplate] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTemplate = async () => {
      if (templateId) {
        setLoading(true);
        try {
          const { data } = await api.get(`/templates/${templateId}`);
          setTemplate(data);
        } catch (error) {
          toast.error("Erro ao carregar os dados.");
        } finally {
          setLoading(false);
        }
      } else {
        setTemplate(initialState);
      }
    };

    fetchTemplate();
  }, [templateId, open]);

  const handleClose = () => {
    onClose();
    setTemplate(initialState);
  };

  const handleSave = async (values) => {
    try {
      let response;
      if (templateId) {
        response = await api.put(`/templates/${templateId}`, values);
        toast.success("Template atualizado com sucesso!");
      } else {
        response = await api.post("/templates", values);
        toast.success("Template adicionado com sucesso!");
      }
      onSave(response.data);
    } catch (error) {
      toast.error("Erro ao salvar os dados.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{templateId ? `Editar Template` : "Adicionar Template"}</DialogTitle>
      {loading ? (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Formik
          initialValues={template}
          enableReinitialize
          validationSchema={TemplateSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSave(values);
            setSubmitting(false);
            handleClose();
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label="Nome"
                  name="name"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  required
                />
                <Field
                  as={TextField}
                  label="Descrição"
                  name="description"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows={4}
                />
                <FormControl fullWidth margin="dense" variant="outlined" required>
                  <InputLabel id="type-select-label">Tipo</InputLabel>
                  <Select
                    labelId="type-select-label"
                    label="Tipo"
                    value={values.type || ""}
                    onChange={(e) => setFieldValue("type", e.target.value)} 
                  >
                    <MenuItem value="utility">Utilidades</MenuItem>
                  </Select>
                </FormControl>

                <Field
                  as={TextField}
                  label="Idioma"
                  name="lang"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  required
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="outlined">
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {templateId ? "Salvar" : "Adicionar"}
                  {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default TemplateModal;
